<template>
  <div class="row p-strip">
    <div class="col-12">
      <div class="row u-vertically-center">
        <div class="col-4 u-align-text--center">
          <img :src="require('@/assets/contact-sebastian-hagens.jpg')" class="contact-foto" />
        </div>
        <div class="col-8 u-align-text--center">
          <span class="p-heading--1">info@sebastix.nl</span>
          <br />
          <br />
          <span class="p-heading--1">06 4171 2778</span>
          <br />
          <br />
          <a href="./avw_sebastix.pdf" class="p-button--base is-inline">algemene voorwaarden</a>
          <a href="./privacy_statement.pdf" class="p-button--base is-inline">privacy statement</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact.vue"
}
</script>

<style scoped lang="scss">
  img.contact-foto {
    width: 100%;
    clip-path: circle(50% at center);
  }
  .p-button--base, .p-button--base:hover, .p-button--base:active {
    border-radius: 0;
    border: 0;
    color: var(--backgroundColor);
    background-color: rgba(255, 255, 255, 0.5);
    &:hover {
      background-color: rgba(255, 255, 255, 0.9);
    }
  }
</style>