<template>
  <div class="home">
    <div class="p-strip">
      <div class="row">
        <div class="col-6 logo">
          <span class="u-align-text--center u-vertically-center">
            Sebastian Hagens
            <br />
            Tech consultant & creative webdeveloper
          </span>
          <img alt="Sebastix logo" src="@/assets/sebastix-logo.jpg" loading="lazy" class="u-align-text--center" />
        </div>
        <div class="col-6 u-align--center">
          <a href="#" v-on:click="toggleSebastian" class="photo">
            <img alt="Sebastian Hagens" :src="sebastianHagensSrc" :srcset="sebastianHagensSrcset" class="sebastian-hagens" v-bind:class="{ 'clicked' : isClicked }" v-on:mouseout="isClicked = false" />
          </a>
        </div>
      </div>
    </div>
    <div class="p-strip">
      <div class="row">
        <h1>
          Als <u>creatieve techneut</u> help ik individuen <br />
          en bedrijven met het <u>oplossen</u> van <br />
          ambitieuze <u>digitale vraagstukken</u>
        </h1>
      </div>
      <div class="row">
        <div class="col-12 u-align-text--center">
          <Button text="Benieuwd hoe ik dat doe?" class="benieuwd-c2a"></Button>
        </div>
      </div>
    </div>
    <div class="l-fluid-breakout__main is-full-width blue-gradient-bg-75 tools">
      <div class="row">
        <div class="col-7">
          <img :src="require('@/assets/bureau-sebastian-hagens.jpg')" class="bureau-sebastian-hagens" />
        </div>
        <div class="col-5 logos">
          <h2 class="p-heading--2">Breed scala aan tools</h2>
          <p>Mijn werkzaamheden vragen om een goed gevulde gereedschapskist:</p>
          <div class="skills">
            <div class="f-row">
              <span class="circle-bg p-tooltip--top-center">
                <img :src="require('@/assets/svg/html-logo.svg')" alt="HTML" />
                <span class="p-tooltip__message" role="tooltip" id="tp-rght">HTML</span>
              </span>
              <span class="circle-bg p-tooltip--top-center">
                <img :src="require('@/assets/svg/css3-logo.svg')" alt ="CSS" />
                <span class="p-tooltip__message" role="tooltip" id="tp-rght">CSS</span>
              </span>
              <span class="circle-bg p-tooltip--top-center">
                <img :src="require('@/assets/svg/sass-logo.svg')" alt="SASS" />
                <span class="p-tooltip__message" role="tooltip" id="tp-rght">SASS</span>
              </span>
              <span class="circle-bg p-tooltip--top-center">
                <img :src="require('@/assets/svg/javascript-logo.svg')" alt="Javascript" />
                <span class="p-tooltip__message" role="tooltip" id="tp-rght">Javascript</span>
              </span>
              <span class="circle-bg p-tooltip--top-center">
                <img :src="require('@/assets/svg/vuejs-logo.svg')" alt="Vuejs" />
                <span class="p-tooltip__message" role="tooltip" id="tp-rght">Vuejs</span>
              </span>
            </div>
            <div class="f-row">
              <span class="circle-bg p-tooltip--top-center">
                <img :src="require('@/assets/svg/php-logo.svg')" alt="PHP" />
                <span class="p-tooltip__message" role="tooltip" id="tp-rght">PHP</span>
              </span>
              <span class="circle-bg p-tooltip--top-center">
                <img :src="require('@/assets/svg/symfony-logo.svg')" alt="Symfony" />
                <span class="p-tooltip__message" role="tooltip" id="tp-rght">Symfony</span>
              </span>
              <span class="circle-bg p-tooltip--top-center">
                <img :src="require('@/assets/svg/drupal-logo.svg')" alt="Drupal" />
                <span class="p-tooltip__message" role="tooltip" id="tp-rght">Drupal</span>
              </span>
              <span class="circle-bg p-tooltip--top-center">
                <img :src="require('@/assets/svg/mysql-logo.svg')" alt="MySQL" />
                <span class="p-tooltip__message" role="tooltip" id="tp-rght">MySQL</span>
              </span>
              <span class="circle-bg p-tooltip--top-center">
                <img :src="require('@/assets/svg/redis-logo.svg')" alt="Redis" />
                <span class="p-tooltip__message" role="tooltip" id="tp-rght">Redis</span>
              </span>
            </div>
            <div class="f-row">
              <span class="circle-bg p-tooltip--top-center">
                <img :src="require('@/assets/svg/gitlab-logo.svg')" alt="Gitlab" />
                <span class="p-tooltip__message" role="tooltip" id="tp-rght">Gitlab</span>
              </span>
              <span class="circle-bg p-tooltip--top-center">
                <img :src="require('@/assets/svg/docker-logo.svg')" alt="Docker" />
                <span class="p-tooltip__message" role="tooltip" id="tp-rght">Docker</span>
              </span>
              <span class="circle-bg p-tooltip--top-center">
                <img :src="require('@/assets/svg/nginx-logo.svg')" alt="Nginx" />
                <span class="p-tooltip__message" role="tooltip" id="tp-rght">Nginx</span>
              </span>
              <span class="circle-bg p-tooltip--top-center">
                <img :src="require('@/assets/svg/apache-logo.svg')" alt="Apache" />
                <span class="p-tooltip__message" role="tooltip" id="tp-rght">Apache</span>
              </span>
              <span class="circle-bg p-tooltip--top-center">
                <img :src="require('@/assets/svg/git-logo.svg')" alt="Git" />
                <span class="p-tooltip__message" role="tooltip" id="tp-rght">Git</span>
              </span>
            </div>
            <div class="f-row">
              <span class="circle-bg p-tooltip--top-center">
                <img :src="require('@/assets/svg/sketch-logo.svg')" alt="Sketch" />
                <span class="p-tooltip__message" role="tooltip" id="tp-rght">Sketch</span>
              </span>
              <span class="circle-bg p-tooltip--top-center">
                <img :src="require('@/assets/svg/nodejs-logo.svg')" alt="Nodejs" />
                <span class="p-tooltip__message" role="tooltip" id="tp-rght">Nodejs</span>
              </span>
              <span class="circle-bg p-tooltip--top-center">
                <img :src="require('@/assets/svg/ubuntu-logo.svg')" alt="Ubuntu" />
                <span class="p-tooltip__message" role="tooltip" id="tp-rght">Ubuntu</span>
              </span>
              <span class="circle-bg p-tooltip--top-center">
                <img :src="require('@/assets/svg/linux-logo.svg')" alt="Linux" />
                <span class="p-tooltip__message" role="tooltip" id="tp-rght">Linux</span>
              </span>
              <span class="circle-bg p-tooltip--top-center">
                <img :src="require('@/assets/svg/vanilla-logo.svg')" alt="Vanilla CSS framework" />
                <span class="p-tooltip__message" role="tooltip" id="tp-rght">Vanilla CSS framework</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row short-bio">
        <div class="col-4">
          <p class="p-heading--4">
            Al vanaf mijn 12e maak ik dingen op het web. Webapplicaties maken was in die tijd nog eenvoudig. Nu is webtechnologie een stuk geadvanceerder geworden. Met een open vizier volg ik alle ontwikkelingen op de voet. Deze kennis gebruik ik bij het realiseren van passende oplossingen voor verschillende digitale vraagstukken.
          </p>
        </div>
        <div class="col-4">
          <ul class="p-heading--4">
            <li>Hulp nodig bij het waarmaken van jouw digitale ambities?</li>
            <li>Zoek je iemand die jou wegwijs maakt in verschillende technische mogelijkheden?</li>
            <li>Heb je behoefte aan een second-opinion bij het ontwikkelen van een complexe website of applicatie?</li>
          </ul>
        </div>
        <div class="col-4">
          <p class="p-heading--4">
            Als technisch consultant voor advies én als creative webdeveloper help ik je graag verder.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SebastixSaysHi from '@/components/SebastixSaysHi.vue'
import Button from '@/components/Button.vue'

export default {
  name: 'Home',
  components: {
    SebastixSaysHi,
    Button
  },
  data() {
    return {
      toggleSebastianNumber: 0,
      sebastianHagensSrc: '',
      sebastianHagensSrcset: '',
      isClicked: false
    }
  },
  mounted() {
    this.toggleSebastian();
  },
  methods: {
    toggleSebastian: function(e) {
      // Add clicked class
      if (e !== undefined) {
        this.isClicked = true;
      }
      // Change src of image
      if (this.toggleSebastianNumber === 4) {
        this.toggleSebastianNumber = 1;
      } else {
        this.toggleSebastianNumber++;
      }
      this.sebastianHagensSrc = require('@/assets/sebastian-hagens-'+this.toggleSebastianNumber+'.jpg');
      this.sebastianHagensSrcset = require('@/assets/sebastian-hagens-'+this.toggleSebastianNumber+'@2x.jpg') + ' 2x';
    }
  }
}
</script>

<style scoped lang="scss">
  .home {
    .p-strip:first-child {
      padding-bottom: 0;
    }
    .logo {
      position: relative;
      font-family: "Ubuntu Mono",serif;
      text-align: center;
      display: flex;
      flex-flow: column;
      img {
        max-width: 50vw;
        order: 1;
        margin-bottom: 30px;
        @media (min-width: 772px) {
          position: absolute;
          top: calc(50% - 100px);
          left: 30px;
          height: 200px;
          margin: 0;
          z-index: 5;
        }
      }
      > span {
        order: 2;
        padding: 10px 0;
        border-radius: 100px;
        background-color: #000;
        width: 100%;
        margin-bottom: 30px;
        @media (min-width: 772px) {
          position: absolute;
          top: calc(85% - 40px);
          left: 200px;
          width: 400px;
          height: 80px;
          margin: 0;
          z-index: 3;
        }
        @media (min-width: 930px) {
          top: calc(50% - 40px);
        }
        @media (min-width: 1681px) {
          width: 440px;
        }
      }
    }
    .benieuwd-c2a {
      margin-top: 4rem;
    }
    a.photo {
      display: inline-block;
      border-bottom: 0;
      &:active {
        img.sebastian-hagens {
          clip-path: circle(47% at center);
          transform: scale(.925);
          /* */
          @media screen and (max-width: 767px) {
            transform: scale(.9);
          }
        }
      }
    }
    img.sebastian-hagens {
      transition: 0.4s cubic-bezier(1, -1, 0, 2);
      clip-path: circle(49% at center);
      &:hover {
        clip-path: circle(50% at center);
        transform: scale(1.015);
      }
      &.clicked {
        transition: 0.1s ease-out;
        &:hover {
          clip-path: circle(50% at center);
          transform: scale(1);
        }
      }
    }

    h1 {
      font-weight: 300;
      margin: 30px 0 30px 0;
      @media (min-width: 772px) {
        text-align: center;
        font-size: 58px;
        font-weight: 100;
        line-height: 70px;
      }
    }

    .tools {
      .row:first-child {
        .logos {
          @media (max-width: 772px) {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
          }
        }
        img.bureau-sebastian-hagens {
          @media (min-width: 772px) {
            clip-path: polygon(0 0, 70% 0, 100% 100%, 0% 100%);
          }
          @media (min-width: 1270px) {
            clip-path: polygon(0 0, 73% 0, 100% 100%, 0% 100%);
          }
          @media (min-width: 1681px) {
            clip-path: polygon(0 0, 80% 0, 100% 100%, 0% 100%);
          }
        }

        h2 {
          margin-top: 0.9rem;
          @media (min-width: 772px) {
            margin-top: 0.8rem;
            margin-left: -7.4rem;
          }
          @media (min-width: 930px) {
            margin-top: 0.8rem;
            margin-left: -10rem;
          }
        }

        p {
          @media (min-width: 772px) {
            margin-left: -5rem;
          }
          @media (min-width: 930px) {
            margin-left: -8.5rem;
          }
        }

        > .row > div:nth-child(2) {
          padding-left: 1rem;
          padding-right: 1rem;
        }
      }

      .skills {
        @media (min-width: 772px) {
          margin-left: -2rem;
        }
        @media (min-width: 930px) {
          margin-left: -4rem;
        }
        @media (min-width: 1270px) {
          margin-left: -5rem;
        }
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 10px;
        @media (min-width: 772px) {
          row-gap: 0;
        }

        .f-row {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: flex-start;
          flex: 0 1 100%;

          &:nth-child(1) {
            @media (min-width: 772px) {
              margin-left: calc(-29vw / 5 / 2);
            }
            @media (min-width: 930px) {
              margin-left: calc(-60px / 2);
            }
            @media (min-width: 1270px) {
              margin-left: calc(-80px / 2);
            }
          }

          &:nth-child(2) {
            @media (min-width: 772px) {
              margin-left: 5px;
            }
            @media (min-width: 930px) {
              margin-left: 5px;
            }
            @media (min-width: 1270px) {
              margin-left: 5px;
            }
          }

          &:nth-child(3) {
            @media (min-width: 772px) {
              margin-left: calc(29vw / 5 / 2 + 10px);
            }
            @media (min-width: 930px) {
              margin-left: calc(78px / 2);
            }
            @media (min-width: 1270px) {
              margin-left: calc(98px / 2);
            }
          }

          &:nth-child(4) {
            @media (min-width: 772px) {
              margin-left: calc(29vw / 5 + 14px);
            }
            @media (min-width: 930px) {
              margin-left: 76px;
            }
            @media (min-width: 1270px) {
              margin-left: 96px;
            }
          }

          .circle-bg {
            background-color: var(--textColor);
            text-align: center;
            width: calc(76vw / 5);
            height: calc(76vw / 5);
            margin: 0 5px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            // Between 772px and 930px the logos needs to be responsive
            @media (min-width: 772px) {
              width: calc(28vw / 5);
              height: calc(28vw / 5);
            }
            @media (min-width: 930px) {
              width: 60px;
              height: 60px;
            }
            @media (min-width: 1270px) {
              width: 80px;
              height: 80px;
            }
            @media (min-width: 1681px) {
              width: calc(35vw / 5);
              height: calc(35vw / 5);
            }

            img {
              width: 60%;
              @media (min-width: 1270px) {
                width: auto;
              }
            }
          }
        }
      }
    }
    .short-bio {
      padding-top: 1.5rem;
      p {

      }
    }
  }
</style>
