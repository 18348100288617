<template>
  <header id="navigation" class="p-navigation">
    <div class="p-navigation__row">
      <div class="p-navigation__banner u-align-text--center">
        <a href="#navigation" class="p-navigation__toggle--open" title="menu">
          <i class="p-icon--menu is-light"></i>
        </a>
        <a href="#navigation-closed" class="p-navigation__toggle--close" title="close menu">
          <i class="p-icon--close is-light"></i>
        </a>
      </div>
      <nav class="p-navigation__nav u-align--center" aria-label="Menu">
        <ul class="p-navigation__items">
          <li class="p-navigation__item u-vertically-center u-hide--small">
            <i class="p-icon--menu"></i>
          </li>
          <li class="p-navigation__item">
            <router-link to="/" class="p-side-navigation__link">home</router-link>
          </li>
          <li class="p-navigation__item">
            <router-link to="/" class="p-side-navigation__link u-hide">portfolio</router-link>
          </li>
          <li class="p-navigation__item">
            <router-link to="/blog" class="p-side-navigation__link">blog</router-link>
          </li>
          <li class="p-navigation__item">
            <router-link to="/contact" class="p-side-navigation__link">contact</router-link>
          </li>
          <li class="p-navigation__item--dropdown-toggle u-hide u-hide--small" id="link-1-menu">
            <a href="#link-1-menu" aria-controls="link-1-menu" class="p-navigation__link" v-on:click="toggleDropdown" v-click-outside="onClickOutside">
              <i class="p-icon--chevron-down is-light"></i>
            </a>
            <ul class="p-navigation__dropdown" aria-hidden="true">
              <li>
                <router-link to="/diensten" class="p-navigation__dropdown-item">Diensten</router-link>
              </li>
              <li>
                <router-link to="/playground" class="p-navigation__dropdown-item">Playground</router-link>
              </li>
              <li>
                <router-link to="/tools" class="p-navigation__dropdown-item">Tools</router-link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
import vClickOutside from 'v-click-outside'

export default {
  name: "Navigation",
  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {
      toggles: [],
    }
  },
  mounted: function() {
    // Init dropdown
    this.toggles = [].slice.call(document.querySelectorAll('.p-navigation__item--dropdown-toggle [aria-controls]'));
  },
  methods: {
    toggleDropdown (e) {
      e.preventDefault();
      let toggle = e.target;
      if (e.target.nodeName === 'I') {
        toggle = e.target.parentNode;
      }
      var parentElement = toggle.parentNode;
      var dropdown = document.getElementById(toggle.getAttribute('aria-controls'));
      dropdown.setAttribute('aria-hidden', !toggle.classList.contains('is-active'));

      if (parentElement.classList.contains('is-active')) {
        parentElement.classList.remove('is-active');
      } else {
        parentElement.classList.add('is-active');
      }
    },
    onClickOutside (e) {
      // Close all dropdowns
      this.toggles.forEach(function(toggle) {
        toggle.parentElement.classList.remove('is-active');
      });
    }
  }
}
</script>

<style scoped lang="scss">
#navigation {
  &.p-navigation {
    text-align: center;
    background-color: transparent;
    .p-navigation__banner {
      padding: 0;
      a {
        transform: scale(2);
        border-bottom: 0;
        &:hover, &:active {
          background-color: transparent;
        }
      }
    }
    .p-navigation__item {
      &:first-child {
        i {
          margin: 0 20px;
        }
      }
      @media (max-width: 772px) {
        text-align: center;
      }
      a {
        @media (max-width: 772px) {
          display: block;
        }
      }
    }
    .p-navigation__item--dropdown-toggle {
      a {
        border-bottom: none;
        &:hover, &:active {
          border-bottom: none;
          background-color: transparent;
        }
      }
      &::after {
        background-image: none;
      }
      &.is-active {
        a {
          background-color: transparent;
          opacity: 1;
        }
      }
    }
    .p-navigation__dropdown {
      li {
        a {
          font-size: 20px;
          color: var(--blueColor);
          border-bottom: 0;
          margin: 0;
          padding: 0 20px;
          line-height: 48px;
          &:hover {
            background-color: #EEE;
          }
        }
      }
    }
  }

  a {
    color: var(--textColor);
    opacity: .5;
    @media (max-width: 772px) {
      opacity: .85;
    }
    padding: 0;
    font-weight: 400;
    line-height: 60px;
    font-size: 28px;
    margin: 0 20px;
    border-bottom-width: 3px;
    transition: border-bottom-color, opacity 0.2s ease-in-out;
    &:hover {
       opacity: 1;
       border-bottom-color: var(--textColor);
    }
    &.router-link-exact-active {
      opacity: 1;
      border-bottom-color: var(--textColor);
    }
  }
}
</style>