import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Conicgradient from '../views/Conicgradient.vue'
import Contact from '../views/Contact.vue'
import Playground from "../views/Playground";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/diensten',
    name: 'Diensten',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Services.vue')
  },
  {
    path: '/playground',
    name: 'Playground',
    component: Playground
  },
  {
    path: '/conic-gradient',
    name: 'Conic gradient',
    component: Conicgradient
  },
  {
    path: '/contact',
    'name': 'Contact',
    component: Contact
  },
  {
    path: '/blog',
    beforeEnter() {
      window.open('https://www.sebastix.nl/blog', '_blank');
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
