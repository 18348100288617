<template>
  <button class="btn">
    {{ text }}
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    text: String
  }
}
</script>

<style scoped lang="scss">
  /** See https://caniuse.com/mdn-css_at-rules_property and https://caniuse.com/css-paint-api */
  @supports (background: paint(houdini)) {
    @property --conicGradientAngle {
      initial-value: -65deg;
      inherits: false;
      syntax: '<angle>';
    }
    @property --colorGradient1 {
      initial-value: #090A0F;
      inherits: false;
      syntax: '<color>';
    }
    @property --colorGradient1PosX {
      initial-value: 50%;
      inherits: false;
      syntax: '<percentage>';
    }
  }

  button {
    background: none;
    text-decoration: inherit;
    font-size: 24px;
    padding: 18px 28px;
    margin: 0;
    outline: transparent;
    cursor: pointer;

    &.btn {
      color: var(--textColor);
      --conicGradientAngle: -65deg;
      --colorGradient1: var(--backgroundColor);
      --colorGradient1PosX: 10%;
      border-style: solid;
      border-image-slice: 1;
      border-width: 5px;
      border-image-source: conic-gradient(from var(--conicGradientAngle) at var(--colorGradient1PosX) 50%, var(--primaryColor), 30deg, var(--secundaryColor), 90deg, var(--colorGradient1), 230deg, var(--primaryColor));
      transition: --conicGradientAngle 0.15s ease-in-out, --colorGradient1 0.6s ease-in-out, --colorGradient1PosX 0.15s ease-in-out;
      /** Fallback for the gradient animation */
      @supports not (background: paint(houdini)) {
        &:hover {
          --conicGradientAngle: 20deg;
          --colorGradient1: var(--secundaryColor);
          --colorGradient1PosX: 50%;
          border-image-source: conic-gradient(from var(--conicGradientAngle) at var(--colorGradient1PosX) 50%, var(--primaryColor), 95deg, var(--colorGradient1), 230deg, var(--primaryColor));
          background-color: transparent;
        }
      }
      @supports (background: paint(houdini)) {
        &:hover {
          --conicGradientAngle: -40deg;
          --colorGradient1: var(--secundaryColor);
          --colorGradient1PosX: 50%;
          background-color: transparent;
        }
      }
    }
  }
</style>