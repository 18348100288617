<template>
  <div class="row">
    <div class="col-12">
      <h1>Playground</h1>
      <Conicgradient></Conicgradient>
    </div>
    <div class="col-12">
      <h2>Texts, paragraphs, formatting</h2>
      <code>This is some code</code>
      <blockquote>This is a blockquote</blockquote>
    </div>
  </div>
</template>

<script>
import Conicgradient from "./Conicgradient";

export default {
  name: "Playground.vue",
  components: {
    Conicgradient
  }
}
</script>

<style scoped>

</style>