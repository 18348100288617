<template>

</template>

<script>
export default {
  name: 'SebastixSaysHi',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
