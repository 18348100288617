<template>
  <div class="conic-gradient">
    <button class="conic-animated-border">
      Conic gradient border on button with hover transition
    </button>
    <br />
    <div class="conic2">
      background
    </div>
  </div>
</template>

<script>
export default {
  name: 'Conicgradient',
  components: {

  }
}
</script>

<style lang="scss">
  /** See https://caniuse.com/mdn-css_at-rules_property and https://caniuse.com/css-paint-api */
  @supports (background: paint(houdini)) {
    @property --conicGradientAngle {
      initial-value: -65deg;
      inherits: false;
      syntax: '<angle>';
    }
    @property --colorGradient1 {
      initial-value: #090A0F;
      inherits: false;
      syntax: '<color>';
    }
    @property --colorGradient1PosX {
      initial-value: 50%;
      inherits: false;
      syntax: '<percentage>';
    }
  }

  .conic-gradient {
    text-align: center;

    > *[class^='conic'] {
      display: inline-block;
      margin-bottom: 30px;
    }

    button {
      background: none;
      text-decoration: inherit;
      font-size: 1rem;
      padding: 2rem 4rem;
      outline: transparent;
      cursor: pointer;
    }

    .conic-animated-border {
      color: #FFF;
      font-size: 24px;
      --conicGradientAngle: -65deg;
      --colorGradient1: #090A0F;
      --colorGradient1PosX: 10%;
      border-style: solid;
      border-image-slice: 1;
      border-width: 15px;
      border-image-source: conic-gradient(from var(--conicGradientAngle) at var(--colorGradient1PosX) 50%, #F05223, 30deg, #F57C31, 90deg, var(--colorGradient1), 230deg, #F05223);
      transition: --conicGradientAngle 0.15s ease-in-out, --colorGradient1 0.6s ease-in-out, --colorGradient1PosX 0.15s ease-in-out;
      /** Fallback for the gradient animation */
      @supports not (background: paint(houdini)) {
        &:hover {
          --conicGradientAngle: 20deg;
          --colorGradient1: #F57C31;
          --colorGradient1PosX: 50%;
          border-image-source: conic-gradient(from var(--conicGradientAngle) at var(--colorGradient1PosX) 50%, #F05223, 95deg, var(--colorGradient1), 230deg, #F05223);
          background-color: transparent;
        }
      }
      @supports (background: paint(houdini)) {
        &:hover {
          --conicGradientAngle: -40deg;
          --colorGradient1: #F57C31;
          --colorGradient1PosX: 50%;
        }
      }
      &:hover {

      }
    }

    .conic2 {
      padding: 2rem 4rem;
      background: conic-gradient(from -65deg at 10% 50%, #F05223, 30deg, #F57C31, 95deg, #090A0F, 230deg, #F05223);
      &:hover {
        background: conic-gradient(from -40deg at 50% 50%, #F05223, 30deg, #F05223, 95deg, #F57C31, 230deg, #F05223);
      }
    }
  }
</style>