<template>
  <div id="app" class="l-site">
    <Navigation></Navigation>
    <main>
      <transition name="fade" appear mode="out-in">
        <router-view/>
      </transition>
    </main>
    <Footer></Footer>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation";
import Footer from "@/components/Footer.vue";

export default {
  name: 'App',
  components: {
    Navigation,
    Footer
  },
  data() {
    return {

    }
  },
  methods: {

  }
}
</script>

<style lang="scss">

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--textColor);
  .p-strip:first-child {
    @media (min-width: 772px) {
      padding-top: 6rem;
    }
  }
}

.fade {
  &-enter-active,
  &-leave-active{
    transition: opacity 0.15s;
  }
  &-enter,
  &-leave-to {
    opacity: 0;
  }
}

</style>
